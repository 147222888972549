/*Accounts Pre/Post Login  */
export const FORGOT_PASSWORD = 'accounts/emaillink';
export const VALIDATE = 'accounts/activate/validate';
export const VALIDATE_OTP = 'accounts/activate/validateotp';
export const ACTIVATE = 'accounts/activate';
export const RESET_PASSWORD = 'accounts/resetpassword';
export const VERIFY_LINK = 'accounts/verifylink';
export const SAVE_USER_LOGIN_ACTIVITY_RESOURCE = 'login/logPlatformUser';

export const CHANGE_PASSWORD = 'accounts/users/current/changepassword';
export const NOTIFICATION_PREFERENCE = 'accounts/users/current/notificationPreferences';
export const NOTIFICATION_PREFERENCE_SUBMIT = 'accounts/users/current/updateNotificationPreferences';
export const UPDATE_USER = 'accounts/users/current';
export const UPDATE_PROFILE_IMAGE = 'accounts/users/current/manageUserProfileImage';
export const GET_PROFILE = 'accounts/users/current/getUserProfileImage';
export const GET_USER_IMAGE = 'accounts/users/current/getUserImage/{userId}';
export const GET_SELF_REGISTRATION_STATUS = 'services/selfRegistration/SelfRegistrationStatus';
export const GET_SELF_REGISTRATION_CONFIG = 'accounts/Register/config';
//export const GET_USER_DETAILS_BYUSERID = 'accounts/UserInfo/{userId}';
export const CHANGE_SECURITY_QUESTION = 'accounts/users/current/changesecurityquestion';
export const GET_SECURITY_QUESTION = 'accounts/users/current/securityquestion';
export const GET_PRELOGIN_POLICY_PAGE_CONTENT = 'external/getpolicycontent/{culture}/{policyType}';
export const GET_COUNTRIES = 'external/country';
export const GET_STATES = 'external/state';
export const GET_CITIES = 'external/city';
export const GET_STATUSES = 'external/getUserStatus';

/* Cookie Policy */

export const GET_COOKIE_POLICY = "cookiepolicy";
export const SET_COOKIE_POLICY = "cookiepolicy/accept";
export const IS_COOKIE_EXISTS = "cookiepolicy/iscookieexists"

/* Dashboard */
export const GET_HOME_CONFIG = "home/configs";

/*Content Pages */
export const GET_CONTENT_PAGE = 'contentpage/view/{contentPageId}/{culture}';
export const GET_REGISTER_CONTENT_PAGE = 'contentpage/registerview/{contentPageId}/{culture}';
export const GET_CONTENT_PAGE_PREVIEW = 'contentpage/view/{contentPageId}/{culture}';

/* Claim */
export const GET_CLAIM_CONFIGURATION = "ClaimConfiguration/{claimFormId}";

/*Coupon Claim */
export const CLAIM_COUPONS = "couponClaim/claimCoupons";
export const GET_COUPON_CLAIM_HISTORY =
  "couponClaim/GetCouponClaimHistory?{params}";
export const GET_COUPON_BY_UPLOADID =
  "couponClaim/getCouponByUploadId?{params}";
export const COUPON_CLAIM_UPLOAD =
  "couponClaim/getCouponClaimUpload?source=Upload";
export const DOWNLOAD_COUPON_UPLOADED_FILE = "customUploads/{uploadId}/rows";
export const DOWNLOAD_COUPON_CLAIM_HISTORY =
  "couponClaim/DownLoadCouponClaimHistory?{params}";

// Order History
export const GET_ORDER_HISTORY_DETAILS = "orderinformation/order/?page={page}&perPage={perPage}";
export const RESEND_VOUCHER = "orderinformation/resendvoucher";

/*Invoice Claim */
export const GET_INVOICE_CLAIM_HISTORY = 'claims/InvoiceClaimHistory?{params}';
export const GET_INVOICE_PARENT_CHILD_CLAIM_HISTORY = 'claims/InvoiceClaimParentChildHistory?{params}';
export const GET_INVOICES_FOR_APPROVAL = 'claims/InvoiceResolvingAudience?{params}';
export const GET_INVOICE_CLAIM_FIELDS = 'claims/getInvoiceClaimFields/{claimId}';
export const GET_INVOICE_PRODUCTS = 'claims/getProducts/{productMasterId}/{claimFormId}';
export const GET_INVOICE_PRODUCTS_BY_ATTRIBUTES = 'claims/getProductsByAttributes';
export const GET_INVOICE_PRODUCTS_HEADER = 'claims/getProductsHeader/{productMasterId}/{claimFormId}';
export const GET_INVOICE_SUBMISSION_PRODUCTS = 'claims/getInvoiceSubmissionProducts/{productMasterId}/{claimFormId}';
export const INVOICE_SUBMISSION = 'claims/invoiceSubmission';
export const INVOICE_RESUBMISSION = 'claims/invoiceSubmission/update';
export const INVOICE_FILE_UPLOAD = 'claims/invoiceSubmissionFilesUpload/{invoiceId}';
export const INVOICE_FILE_REUPLOAD = 'claims/invoiceResubmissionFilesUpload/{invoiceId}';
export const DOWNLOAD_INVOICE_CLAIM_HISTORY = 'claims/DowmloadInvoiceHistory?{params}';
export const DOWNLOAD_INVOICE_APPROVAL = 'claims/DowmloadInvoicesForApprovel?{params}';
export const DOWNLOAD_INVOICE_FILES = 'claims/downLoadInvoiceFile?fileLocation={fileLocation}&fileName={fileName}&filePath={filePath}&fileType={fileType}&invoiceId={invoiceId}';
export const GET_INVOICECLAIM_UPLOAD = 'claims/getInvoiceClaimUpload';
export const UPDATE_INVOICE_STATUS = 'claims/UpdateInvoiceStatus';
export const GET_INVOICE_CLAIM_ULOADED_FILES = 'claims/invoiceClaimUloadedFiles/{invoiceId}';
export const GET_INVOICE_DETAILS = 'claims/invoicecliam/{invoiceId}';
export const GET_ELIGIBLE_ORGANISATION = 'claims/getEligibleOrganisation/{claimFormId}';

/*Sales Claim */
export const GET_SALES = "claims/{claimFormId}/sales?{params}";
export const CHECK_RESOLVER = "claims/{claimFormId}/sales/{saleId}";
export const CLAIM = "claims/{claimFormId}/sales/{saleId}";
export const RESOLVE = "claims/{claimFormId}/sales/{saleId}/resolve";
export const APPROVE = "claims/{claimFormId}/sales/{saleId}/approve";
export const REJECT = "claims/{claimFormId}/sales/{saleId}/reject";

/* PointSummary */
export const GET_POINT_SUMMARY = "accounts/users/current/points/summary?pointType={pointType}";
export const GET_POINT_STATEMENT = "pointsStatement/points?{params}";
export const GET_POINT_STATEMENT_FOR_CHART =
  "pointsStatement/chartdata?fromDate={fromDate}&toDate={toDate}&promotion={promotion}&pointType={pointType}";
export const EXPORT_POINT_STATEMENT = "pointsStatement/export/all?{params}";

/* Promotion */
export const GET_PROMOTIONS_BY_USERID = 'promotions/promotionsByUserId';
export const GET_MY_TARGET = 'promotions/aot/{promotionId}/mytarget';
export const GET_PROMOTION = 'promotions/{promotionId}';

/* Briks */
export const BRIKS_PROMOTION_DATA = "promotions/{promotionId}";
export const ATTEMPT_ALLOWED_DETAILS =
  "quizpages/IsAttemptAllowedOutsideQuizDate/{promotionId}/{userId}";
export const QUIZ_CONTENTS = "contentpage/view/{contentPageId}/{culture}";
export const QUIZ_ATTEMPT_DETAILS =
  "promotions/getAudienceQuizAttemptDetailsforplatform?{params}";

/* Game Arcade */
export const GET_GAME_TOKEN = "game/getGameToken";

/* My Awards */
export const GET_MY_AWARDS = "myawards/{awardsPageId}?{params}";

/* My Threshold Awards */
export const GET_MY_THRESHOLD_AWARDS_DETAILS =
  "mythresholdawardspages/details/{id}";

/* Embedded Report */
export const GET_POWERBI_REPORT =
  "embeddedReport/embedReport/{embeddedReportPageId}/{culture}/{fallBackCulture}";
export const GET_SUPERSET_REPORT = "embeddedReport/SuperSetReport/{superSetReportPageId}/{culture}/{fallBackCulture}";
export const GET_EMBEDDED_REPORT_TYPE ="embeddedReport/type/{id}";

/*Message Pages */
export const GET_MESSAGE_PAGE =
  "messagePages/{messagePageId}/current/{culture}";
export const GET_MESSAGE_CATEGORIES =
  "messagePages/{messagePageId}/categories/current/{culture}";
export const GET_MESSAGE_ARTICLES =
  "messagePages/{messagePageId}/articles/current/{culture}";
export const GET_MESSAGE_ARTICLE =
  "messagePages/{messagePageId}/articles/{messageArticleId}/{culture}";
export const GET_MESSAGE_ARTICLE_PREVIEW =
  "messagePages/{messagePageId}/articles/{messageArticleId}/{culture}/preview";

/*Question Pages */
export const GET_QUESTION_PAGE =
  "questionPages/{questionPageId}/current/{culture}";
export const GET_QUESTION_CATEGORIES =
  "questionPages/{questionPageId}/categories/current/{culture}";
export const GET_QUESTION_ARTICLES =
  "questionPages/{questionPageId}/articles/current/{culture}";
export const GET_QUESTION_ARTICLE =
  "questionPages/{questionPageId}/articles/{questionArticleId}/{culture}";
export const GET_QUESTION_ARTICLE_PREVIEW =
  "questionPages/{questionPageId}/articles/{questionArticleId}/{culture}/preview";

/* GoalQuest Pages */
export const GET_GOALQUEST_PAGES =
  "goalquestpages/{goalQuestId}/culture/{culture}/{fallBackCulture}";
export const GET_MANAGER_GOALQUEST_PAGES =
  "goalquestpages/manager/{goalQuestId}";
export const GET_MOST_REDEEMED_PRODUCTS = "goalquestpages/mostredeemedproducts";
export const CHANGE_REWARD = "goalquestpages/selectreward";
export const DOWNLOAD_GOAL_PROGRESS =
  "goalquestpages/manager/download/goalachievement/{goalQuestId}";
export const DOWNLOAD_GOAL_SELECTION =
  "goalquestpages/manager/download/goalselection/{goalQuestId}";
export const GET_GOALQUEST_MANAGER_HUB_PAGE =
  "goalquestpages/manager/hubpage/{id}";

/* League Pages */
export const GET_LEAGUE_PAGE = "leagues/{leagueId}?language={culture}";
export const GET_MAIN_LEAGUE =
  "promotions/{promotionId}/league/{leagueId}/users/{userId}/pagenumber/{pageNumber}/type/{type}/noperpage/10";

/* Audience */
export const USER_RESOLVING_AUDIENCE =
  "audiences/{resolvingAudienceId}/contains/{userId}";

/* Hub Pages */
export const GET_HUB_PAGE_COMPONENTS = 'hubpages/child/allComponents/{hubPageId}/culture/{culture}';
export const GET_HUB_PAGE_COMPONENT = 'hubpages/child/component/{componentId}/culture/{culture}';
export const GET_MONTHLY_SALES = 'hubpages/monthlySales/{promotionId}/type/{promotionType}';

/* Most Redeem Products */
export const MOST_REDEEM_PRODUCTS =
  "pointsStatement/mostRedeemedProducts/{noOfItems}";

/* Menu */
export const PLATFORM_MENU = 'custommenu/platform';
export const PLATFORM_MENU_BYID = 'menu/platform/{menuId}';
export const REPORT_IMPERSONATE = 'accounts/users/current/impersonate';

/*  threshold hub page  */
export const HUB_PAGE_THRESHOLD_POINTHISTORY =
  "mythresholdawardspages/pointshistory?itemsPerPage={itemsPerPage}&pageNumber={pageNumber}&searchTerm={searchTerm}&sortAscending={sortAscending}&sortField={sortField}&fromSale={fromSale}&toSale={toSale}&promotionID={promotionID}&type={type}";
export const HUB_PAGE_THRESHOLD_USER_ACHIEVEMENTS =
  "mythresholdawardspages/userachivement/{id}";
export const HUB_PAGE_THRESHOLD_SKU_STRUCTURE =
  "mythresholdawardspages/skupointstructure/{id}";

/* Membership Tiering page  */
export const MEMBERSHIP_TIER_HISTORY =
  "membershiptiering/tierhistory?itemsPerPage=10&pageNumber={pageNumber}&searchTerm=&sortAscending=false&sortField=tier";
export const MEMBERSHIP_TIER_ACTIVE_TIERS = "membershiptiering/activetiers";
export const MEMBERSHIP_ACHIEVED_POINTS = "membershiptiering/achivedpoints";

/* Notification Corner */
export const GET_ALL_NOTIFICATIONS = "notifications/messagecentre?{params}";
export const GET_NOTIFICATION_BY_ID = "notifications/messagecentre/{id}";
export const MAKE_NOTIFICATION_AS_READ =
  "notifications/messagecentre/status/{id}";
export const DELETE_NOTIFICATION = "notifications/messagecentre/delete/{id}";
/* Dashboard Page */
export const GET_DASHBOARD_PAGE =
  "platformdashboard/GetPlatformDashboard/{dashboardId}?culture={culture}";
export const GET_USER_ACCOUNT_STATUS_LIST =
  "sharedStandardReportApi/getUserAccountStatusList";
export const GET_ORGANISATION_USER_JOBROLE =
  "sharedStandardReportApi/getOrganisationUserJobRole?organisationID={organisationID}";
export const GET_LOGIN_ACTIVITY_REPORT =
  "loginActivityReport/getLoginActivitySummary?{params}";

/* Contact US */
export const GET_FORM_ELEMENTS = "contactus/prelogin/formconfiguration/{culture}";
export const GET_QUERIES = "contactus/prelogin/query";
export const POST_CONTACT_US = "contactus/prelogin/submit";

export const GET_FORM_ELEMENTS_L = "contactus/postlogin/formconfiguration/{culture}";
export const GET_QUERIES_L = "contactus/postlogin/query";
export const POST_CONTACT_US_L = "contactus/postlogin/submit";
export const GET_PREPOPULATED_VALUES = "contactus/postlogin/formvalues";

/* Policy Content Page */
export const GET_POLICY_PAGE_CONTENT = 'contentpage/policycontent/{culture}/{policyType}';

/* User Registration Approval Page */
export const GET_USER_REGISTRATION_APPROVAL_STATUSES = 'registrationApproval/getApprovalStatuses';
export const GET_USER_REGISTRATION_APPROVAL_JOBROLES = 'registrationApproval/getJobRole';
export const GET_USER_REGISTRATION_APPROVAL_ORGANISATIONS = 'registrationApproval/getOrganisation/{searchText}';
export const GET_USER_REGISTRATION_APPROVAL_DETAILS = 'registrationApproval/getUserApprovalDetails';
export const EXPORT_USER_REGISTRATION_APPROVAL_DETAILS = 'registrationApproval/exportApprovalDetails';
export const USER_REGISTRATION_APPROVE = 'registrationApproval/approve';
export const GET_USER_REGISTRATION_APPROVAl_CONTEXT = 'registrationApproval/getUserRegistrationUpload';

//prebuilt template

export const GET_PREBUILT_TEMPLATE_CSS = 'contentpage/preBuiltTemplates';

/* Pan Validation */
export const GET_IS_PAN_MODIFY_ALLOWED = 'accounts/users/current/isPanModifyAllowed';

/* cash rewards */
export const GET_CASH_REWARDS_SSO = 'cashrewards/getcashrewardsssodetails';
export const SSO_CASH_REWARDS = 'participant/sso';

export function UrlBuilder(url: string, data) {
  Object.entries(data).forEach(([key, value]) => {
    url = url.replace(`{${key}}`, `${value}`);
  });
  return url;
}
